<template>
    <div>
      <v-layout wrap justify-center pt-md-16>
        <v-flex xs12 align-self-center text-center pt-4 pt-sm-12 pt-lg-16>
          <v-card flat text-center class="pt-0">
            <v-layout wrap justify-center pt-xl-16>
              <v-flex xs12 py-5 align-self-center>
                <h2>Your payment is being processed.</h2>
              </v-flex>
              <v-flex
                xs12
                px-3
                align-self-center
                :text-justify="$vuetify.breakpoint.name == 'xs' ? true : false"
              >
                <span
                  style="
                    font-size: 14px !important;
                    text-align: center !important;
                    color: black;
                  "
                >
                  <b>
                    You will be taken to a confirmation page when your payment is
                    complete, or you can leave this page while your payment is
                    being processed and return later.
                    <br />
                    You will be sent a confirmation email once payment is
                    received.
                    <br />
                    <br />
                    For any queries, Please contact 0423-2445971, 948-777-5971
                  </b>
                </span>
              </v-flex>
  
              <v-flex xs12 py-3 align-self-center text-center>
                <a href="/">Click here to redirect to home page.</a>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  export default {
    mounted() {
      window.setInterval(() => {
        this.paymentStatus();
      }, 4000);
    },
    methods: {
      paymentStatus() {
        axios({
          method: "POST",
          url: "/booking/razorpay/checkstatus",
          data: {
            // transid: this.$route.query.pay,
            id: this.$route.query.pay,
            orderid: this.$route.query.order,
          },
        })
          .then((response) => {
            if (response.data.status) {
              var PaymentData = null;
              PaymentData = response.data.data;
              if (PaymentData.payment_status === "success") {
                location.href = "https://ecoshopapi.mudumalaitigerreserve.com/Profile/Orders/";
              } else if (PaymentData.payment_status === "failure") {
                location.href = "https://ecoshopapi.mudumalaitigerreserve.com/cart/";
              }
            } else if (
              response.data.msg == "Failed to verify the payment status"
            ) {
              // location.href =
              //   "https://api.mudumalaitigerreserve.com/Wait?pay=" +
              //   this.$route.query.pay;
                location.href =
                "https://ecoshopapi.mudumalaitigerreserve.com/Wait?pay=" +
                this.$route.query.pay;
            }
          })
          .catch(() => {
            console.log("error");
          });
      },
    },
  };
  </script>